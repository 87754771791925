import styles from './socials.module.scss';
import { ReactComponent as IconTg } from '../../assets/images/icons/icon-telegram.svg';
import { ReactComponent as IconVk } from '../../assets/images/icons/icon-vk.svg';

function Socials({horizontal}) {
  return (
    <ul className={`${styles.list} ${horizontal ? styles.horizontalList : ``} `}>
      <li>
        <a href="#">
          <IconTg />
        </a>
      </li>
      <li>
        <a href="https://vk.com/zabegrf/" target="_blank" rel="noopener noreferrer">
          <IconVk />
        </a>
      </li>
    </ul>
  );
}

export default Socials;
