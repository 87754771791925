import styles from './stat-lines.module.scss';
import { useRef } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';


function StatLines() {
  const triggerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: triggerRef,
    offset: ["start 1", "end -1"]
  });
  const xMoveLeft = useTransform(scrollYProgress, [0, 1], [0, -600]);
  const xMoveRight = useTransform(scrollYProgress, [0, 1], [0, 600]);

  return (
    <section className={styles.statLines} ref={triggerRef}>
      <div className={styles.wrapper}>
        <motion.div style={{ x: xMoveLeft }}>
          <p className={`${styles.line} ${styles.line1}`}>
            4 дистанции <span className={styles.cities1}>6 стран </span>
            4 дистанции <span className={styles.cities1}>6 стран </span>
            4 дистанции <span className={styles.cities1}>6 стран </span>
            4 дистанции <span className={styles.cities1}>6 стран </span>
          </p>
        </motion.div>

        <motion.div style={{ x: xMoveRight }}>
          <p className={`${styles.line} ${styles.line2}`}>
            <span className={styles.cities1}>4 дистанции </span> <span className={styles.textShadow}> более 150 000 участников </span>
            <span className={styles.cities1}>4 дистанции </span> <span className={styles.textShadow}> более 150 000 участников </span>
            <span className={styles.cities1}>4 дистанции </span> <span className={styles.textShadow}> более 150 000 участников </span>
            <span className={styles.cities1}>4 дистанции </span> <span className={styles.textShadow}> более 150 000 участников </span>
          </p>
        </motion.div>

        <motion.div style={{ x: xMoveRight }}>
          <p className={`${styles.line} ${styles.line4}`}>
            11 часовых поясов 11 часовых поясов 11 часовых поясов 11 часовых поясов 11 часовых поясов 11 часовых поясов
          </p>
        </motion.div>

      </div>
    </section>
  );
}

export default StatLines;
