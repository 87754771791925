import styles from './stat-cards.module.scss';
import { Swiper, SwiperSlide, } from 'swiper/react';
import 'swiper/css';
import img1 from '../../assets/images/stat-card1@1x.jpg';
import img2 from '../../assets/images/stat-card5@1x.jpg';
import img3 from '../../assets/images/stat-card3@1x.jpg';
import img4 from '../../assets/images/stat-card4@1x.jpg';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';


const statCards = [
  {
    id: 1,
    bg: img1,
    bgColor: `linear-gradient(180deg, rgba(204, 33, 40, 0.6) 0%, rgba(204, 33, 40, 0.24) 100%)`,
    title: `4 дистанции`,
  },
  {
    id: 2,
    bg: img2,
    bgColor: `linear-gradient(180deg, rgba(50, 47, 205, 0.6) 0%, rgba(50, 47, 205, 0.18) 100%)`,
    title: `6 стран`,
  },
  {
    id: 3,
    bg: img3,
    bgColor: `linear-gradient(180deg, rgba(204, 33, 40, 0.6) 0%, rgba(204, 33, 40, 0.24) 100%)`,
    title: `более 150 000 участников`,
  },
  {
    id: 4,
    bg: img4,
    bgColor: `linear-gradient(180deg, rgba(50, 47, 205, 0.6) 0%, rgba(50, 47, 205, 0.18) 100%)`,
    title: `11 часовых поясов`,
  },
];

function StatCards() {
  const [swiper, setSwiper] = useState();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  useEffect(() => {
    if (isMobile && swiper) {
      swiper.disable();
    } else if (!isMobile && swiper?.__swiper__) {
      swiper.enable();
    }
  }, [isMobile, swiper]);

  return (
    <section className={styles.statCards}>
      <Swiper
        spaceBetween={8}
        slidesPerView={'auto'}
        className={styles.slider}
        onSwiper={(swiper) => setSwiper(swiper)}
      >
        {statCards.map((item) => (
          <SwiperSlide key={item.id}>
            <div
              className={styles.slideImg}
              style={{background: `${item.bgColor}, center / cover no-repeat url(${item.bg})`}}
            >
              <h3 className={styles.title}>{item.title}</h3>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}

export default StatCards;
