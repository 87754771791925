import styles from './footer.module.scss';
import { ReactComponent as IconLinked } from '../../assets/images/icons/linkedin_icon.svg';
import { ReactComponent as IconInstagram } from '../../assets/images/icons/insta_icon.svg';
import { ReactComponent as LogoLiga } from '../../assets/images/logo-liga.svg';
import { ReactComponent as LogoMl } from '../../assets/images/logo-ml.svg';
import { times } from '../../utils/common';
import { useTranslation } from 'react-i18next';


function Footer() {
  const { t } = useTranslation();

  return (
    <footer className={styles.footer}>
      <div className={styles.copyright}>
        <LogoLiga className={styles.logoLiga} width={126} height={80} />
        <p>{t("footer.copyright")} {times.getYear(Date.now() / 1000)} (с)</p>
      </div>
      <div className={styles.incarnation}>
        <p>{t("footer.incarnation")}:</p>
        <LogoMl className={styles.logoMl} width={138} height={38} />
      </div>
    </footer>
  );
}

export default Footer;
