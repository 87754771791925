import styles from './word-lines.module.scss';
import { useRef } from 'react';
import { motion, useScroll, useTransform } from "framer-motion";


function WordLines() {
  const triggerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: triggerRef,
    offset: ["start 1", "end -1"]
  });
  const xMoveLeft = useTransform(scrollYProgress, [0, 1], [0, -600]);
  const xMoveRight = useTransform(scrollYProgress, [0, 1], [0, 600]);

  return (
    <div className={styles.linesWrapper} ref={triggerRef}>
      <motion.div style={{ x: xMoveLeft }}>
        <p className={`${styles.line} ${styles.line1}`}>
          <span className={styles.red}> 4 дистанции</span> 6 стран
          <span className={styles.red}> 4 дистанции</span> 6 стран
          <span className={styles.red}> 4 дистанции</span> 6 стран
          <span className={styles.red}> 4 дистанции</span> 6 стран
        </p>
      </motion.div>

      <motion.div style={{ x: xMoveRight }}>
          <p className={`${styles.line} ${styles.line2}`}>
            <span className={styles.textShadow}> более 150 000 участников </span> 4 дистанции
            <span className={styles.textShadow}> более 150 000 участников </span> 4 дистанции
            <span className={styles.textShadow}> более 150 000 участников </span> 4 дистанции
            <span className={styles.textShadow}> более 150 000 участников </span> 4 дистанции
          </p>
      </motion.div>
    </div>
  );
}

export default WordLines;
