import styles from './prefooter.module.scss';
import { ReactComponent as IconLinked } from '../../assets/images/icons/linkedin_icon.svg';
import { ReactComponent as IconInstagram } from '../../assets/images/icons/insta_icon.svg';
import { ReactComponent as LogoLiga } from '../../assets/images/logo-liga.svg';
import { ReactComponent as LogoMl } from '../../assets/images/logo-ml.svg';
import { times } from '../../utils/common';
import { useTranslation } from 'react-i18next';


function Footer() {
  const { t } = useTranslation();

  return (
    <footer className={styles.prefooter}>
      <div className={styles.email}>
        <p>{t("prefooter.email")}:&nbsp;
          <a href='mailto:info@onerunglobal.com'>info@onerunglobal.com</a>
        </p>
      </div>
      <div className={styles.social}>
        <div>
          <a href="https://www.instagram.com/onerunglobal">
            <IconInstagram className={styles.socialsIcon} width={60} height={60}/>
            <span className="visually-hidden">Instagram</span>
          </a>
          <a href="https://www.linkedin.com/company/heroleague/">
            <IconLinked className={styles.socialsIcon} width={60} height={60}/>
            <span className="visually-hidden">LinkedIn</span>
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
