import { useEffect } from 'react';

export default function HiddenScroll() {
  useEffect(() => {
    document.body.style.overflow = `hidden`;

    return () => {
      document.body.style.overflow = ``;
    }
  }, []);

  return null;
}
