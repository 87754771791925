import styles from './timer.module.scss';
import { useState } from 'react';
import { getRightNames, times } from '../../utils/common';
import { calculateTimeLeft } from '../../hooks/useTimer';
import { useTranslation } from 'react-i18next';


function Timer() {
  const { t } = useTranslation();

  const [date, setDate] = useState(1716112511 );
  const [untilDate, setUntilDate] = useState(times.getNumberDate(date));
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(untilDate));

  return (
    <span className={styles.timer}>
      {/*<span className={styles.timerLabel}>{t("timer.label")} </span>*/}

      <span className={styles.numWrap}>
        <span className={styles.number}>{timeLeft.days}</span>
        <span className={styles.labelNum}>{getRightNames(timeLeft.days, t("timer.1day"), t("timer.2days"), t("timer.5days"))} </span>
      </span>

      <span className={styles.divider}>:</span>

      <span className={styles.numWrap}>
        <span className={styles.number}>{timeLeft.hours}</span>
        <span className={styles.labelNum}>{getRightNames(timeLeft.hours, t("timer.1hour"), t("timer.2hours"), t("timer.5hours"))} </span>
      </span>

      <span className={styles.divider}>:</span>

      <span className={styles.numWrap}>
        <span className={styles.number}>{timeLeft.minutes}</span>
        <span className={styles.labelNum}>{getRightNames(timeLeft.minutes, t("timer.1minute"), t("timer.2minutes"), t("timer.5minutes"))} </span>
      </span>
    </span>
  );
}

export default Timer;
