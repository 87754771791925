import styles from './formats.module.scss';
import Container from '../app/container/container';
import { getCurrentPrice, getNextPrice, times } from '../../utils/common';
import { useState } from 'react';
import FormatBuyModal from '../modals/format-buy/format-buy-modal';
import { useMediaQuery } from 'react-responsive';

const mockFormats = [
  {
    level: `Junior pack`,
    desc: `Каждый из нас с детства мечтает о победах`,
  },
  {
    level: `Started pack`,
    desc: `Все большие свершения начинаются с малого`,
  },
  {
    level: `Classic pack`,
    desc: `Локации и соперничество с лучшими из лучших`,
  },
  {
    level: `Pro pack`,
    desc: `Вызов, подтверждение статуса и пример новичкам`,
  },
];

function Formats({formats}) {
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [format, setFormat] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(-1);
  const isTablet = useMediaQuery({ query: "(max-width: 991px)" });

  const uniteFormats = () => {
    if (formats !== null && formats.length !== 0) {
      const filteredFormatsCity = formats.filter((item) => item.public_id !== `zabeg2023_498817_zaezd`);

      return mockFormats.map((el, index) => Object.assign(el, filteredFormatsCity[index]));
    } else {
      return mockFormats;
    }
  };

  const unitedFormats = uniteFormats();

  const getNextPriceDate = (format) => {
    const isLastPrice = format.price === format.prices[format.prices.length - 1].price;
    return  !isLastPrice ? times.getNumberFullDate(getCurrentPrice(format.prices).start_date) : null;
  };

  const handleClickBuy = (format) => {
    setFormat(format);
    setOpenPopup(true);
  };

  const handleMouseEnterItem = (i) => {
    if (!isTablet) {
      setHoveredItem(i)
    }
  };

  const handleMouseLeaveItem = () => {
    if (!isTablet) {
      setHoveredItem(-1)
    }
  }

  if (formats?.length === 0) return null;

  return (
    <section className={styles.formats} id="formats">
      <Container>
        <h2 className={styles.title}>Форматы забега</h2>

        <ul className={styles.list}>
          {unitedFormats.map((format, index) => (
            <li
              className={`${styles.item} ${(hoveredItem >= 0 && hoveredItem !== index) ? styles.itemHover : ''} ${(hoveredItem >= 0 && hoveredItem === index) ? styles.itemHoverActive : ''}`}
              key={format.public_id}
              onMouseEnter={() => handleMouseEnterItem(index)}
              onMouseLeave={handleMouseLeaveItem}
            >
              <div className={styles.itemContent}>
                <div className={styles.itemTop}>
                  <b className={styles.distance}>{format.title}</b>
                  <p className={styles.level}>{format.level}</p>
                </div>
                <p className={styles.description}>{format.desc}</p>
                <div className={styles.pricesWrap}>
                  <p className={styles.currentPriceWrap}>
                    <span>Цена</span>
                    <span className={styles.price}>{format.price?.toLocaleString()} Р</span>
                  </p>

                  { getNextPrice(format).nextPrice != null &&
                  <p className={styles.nextPriceWrap}>
                    <span>
                      Цена с <span className={styles.nextPriceDate}>{getNextPriceDate(format)}</span>:
                    </span>
                    <span  className={styles.nextPrice}>
                      {format.team_all ? getNextPrice(format).nextPrice * format.max_count : getNextPrice(format).nextPrice?.toLocaleString()} Р
                    </span>
                  </p>
                  }
                </div>
              </div>
              <button
                className={styles.btn}
                type="button"
                onClick={() => handleClickBuy(format)}
                disabled={format.tickets_left < 1}
              >
                {format.tickets_left < 1 ? (
                  `Распродано`
                ): (
                  `Купить`
                )}
              </button>
            </li>
          ))}
        </ul>
      </Container>

      <FormatBuyModal format={format} isShowModal={isOpenPopup} onCloseModal={setOpenPopup} />
    </section>
  );
}

export default Formats;
