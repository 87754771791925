import styles from './start-city.module.scss';
import Container from '../app/container/container';
import img from 'assets/images/start-city@1x.webp';
import icon1 from 'assets/images/icons/icon-dancing.png';
import icon2 from 'assets/images/icons/icon-music.png';
import icon3 from 'assets/images/icons/icon-affection.png';
import { motion } from 'framer-motion';


const activities = [
  {
    id: 1,
    text: `Яркая атмосфера праздника и веселые активности для любого возраста`,
    icon: icon1,
  },
  {
    id: 2,
    text: `Драйвовая музыка от диджея и энергичный ведущий`,
    icon: icon2,
  },
  {
    id: 3,
    text: `Целый день уникальных эмоций!`,
    icon: icon3,
  },
];

const cardVariants = {
  offscreen: {
    scale: 0
  },
  onscreen: {
    scale: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8
    }
  }
};

function StartCity() {
  return (
    <Container>
      <section className={styles.startCity}>
        <h2 className={styles.title}>Стартовый городок</h2>
        <p className={styles.description}>Спортивный фестиваль для тебя и твоей группы поддержки!</p>

        <div className={styles.content}>
          <div className={styles.imgWrap}>
            <img className={styles.img} src={img} alt="Стартовый городок"/>
          </div>

          <ul className={styles.list}>
            {activities.map((item) => (
              <li className={styles.item} key={item.id}>
                <motion.div
                  className="card-container"
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                >
                  <div className="splash" />
                  <motion.div className="card" variants={cardVariants}>
                    <img src={item.icon} alt={item.text} />
                  </motion.div>
                </motion.div>

                <span>{item.text}</span>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </Container>
  );
}

export default StartCity;
