import styles from './main-screen.module.scss';
import PageTop from '../../components/page-top/page-top';
import Countries from '../../components/countries/countries';
import PreFooter from '../../components/prefooter/prefooter';
import Footer from '../../components/footer/footer';
import VideoPlayer from '../../components/video-player/video-player';
import Container from '../../components/app/container/container';
import Faq from '../../components/faq/faq';
import FarewellModal from 'components/modals/FarewellModal/FarewellModal';
import { useState } from 'react';

function MainScreen() {
  const [isOpenFarewell, setIsOpenFarewell] = useState(true);

  return (
    <>
      <PageTop />
      <div className={styles.bg}>
        <Container>
          <VideoPlayer video="https://heroleague.ru/video/onerun2024.mp4" />
        </Container>
        <Countries />
        <Faq />
      </div>

      <FarewellModal isShow={isOpenFarewell} onClose={() => setIsOpenFarewell(false)} />
      <PreFooter />
      <Footer />
    </>
  );
}

export default MainScreen;
