import styles from './country-screen.module.scss'
import PageTop from '../../components/page-top/page-top';
import Description from '../../components/description/description';
import StatLines from '../../components/stat-lines/stat-lines';
import StatCards from '../../components/stat-cards/stat-cards';
import Entertainment from '../../components/entertainment/entertainment';
import HowWasIt from '../../components/how-was-it/how-was-it';
import Formats from '../../components/formats/formats';
import RoutesEvent from '../../components/routes-event/routes-event';
import StarterPack from '../../components/starter-pack/starter-pack';
import StartCity from '../../components/start-city/start-city';
import WordLines from '../../components/word-lines/word-lines';
import Gallery from '../../components/gallery/gallery';
import Faq from '../../components/faq/faq';
import Footer from '../../components/footer/footer';
import { useEffect, useState } from 'react';
import api from '../../api';
import FaqAnchor from '../../components/faq-anchor/faq-anchor';

function CountryScreen() {
  const [city, setCity] = useState({});
  const [formats, setFormats] = useState([]);

  useEffect(() => {
    getFormats();
  }, []);

  const getFormats = () => {
    api.get(`/api/event_format/event/onerun2023_1221874?hidden=true`)
      .then((r) => {
        setFormats(r.data.values);
        setCity(r.data.city);
      })
  };

  return (
    <>
      <PageTop country />
      <Description />
      <StatLines />
      <div className={styles.bg1}>
        <StatCards />
        <Entertainment />
        <HowWasIt />
      </div>
      <Formats formats={formats} />
      <RoutesEvent selectedCity={city} />
      <div className={styles.bg2}>
        <StarterPack />
        <StartCity />
      </div>
      <WordLines />
      <Gallery />
      <Faq />
      <Footer />

      <FaqAnchor />
    </>
  );
}

export default CountryScreen;
