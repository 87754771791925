import styles from './description.module.scss';
import Container from '../app/container/container';


function Description() {
  return (
    <section className={styles.description}>
      <Container>
        <p className={styles.text}>
          <span className={styles.eventName}>One Run</span> – это первый <span className={styles.focus}>международный
          полумарафон с синхронным стартом</span>, на котором каждый участник мотивирует другого на победу.
        </p>
        <p className={styles.slogan}>
          Заряжай бегом, делись энергией, вдохновляй на старт One Run!
        </p>
      </Container>
    </section>
  );
}

export default Description;
