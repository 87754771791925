import styles from './starter-pack.module.scss';
import Container from '../app/container/container';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import imgPack1 from 'assets/images/medal6@2x.png';
import imgPack2 from 'assets/images/medal2.png';
import { Navigation, Pagination } from 'swiper';
import { useRef, useState } from 'react';


const pacs = [
  {
    id: 1,
    img: imgPack1,
    alt: `Медаль финишера`
  },
  {
    id: 2,
    img: imgPack1,
    alt: `Номер участника с чипом хронометража`
  },
  {
    id: 3,
    img: imgPack1,
    alt: `Футболка участника`
  },
  {
    id: 4,
    img: imgPack1,
    alt: `Сертификат участника`
  },
  {
    id: 5,
    img: imgPack1,
    alt: `Подарки от партнеров`
  },
];

function StarterPack() {
  const [activePack, setActivePack] = useState(0);
  const [swiper, setSwiper] = useState();

  const handleChangeSlide = (index) => {
    setActivePack(index);
    // swiper.slideTo(index);
  };

  return (
    <Container>
      <section className={styles.starterPack} id="starter-pack">
        <h2 className={`${styles.title} ${styles.mobile}`}>Стартовый пакет</h2>

        <div className={styles.content}>
          <h2 className={`${styles.title} ${styles.desktop}`}>Стартовый пакет</h2>

          <p className={styles.label}>В пакет входит:</p>

          <ol className={styles.list}>
            {pacs.map((item, index) => (
              <li
                className={`${styles.item} ${index === activePack ? styles.itemActive : ``}`}
                onClick={() => handleChangeSlide(index)}
                key={item.id}
              >
                {item.alt}
              </li>
            ))}
          </ol>

          <p className={styles.footnote}>
            *Пакет участника может меняться в зависимости от города участия
          </p>
          <p className={styles.footnote}>
            *Медаль выдается только на финише
          </p>
        </div>
        <div className={styles.sliderWrap}>
          <div className={styles.placeholder}>
            <img className={styles.medalPlaceholder} src={imgPack2} alt="Медаль"/>
            <p className={styles.textPlaceholder}>В разработке</p>
          </div>
          {/*<Swiper*/}
          {/*  spaceBetween={8}*/}
          {/*  slidesPerView={1}*/}
          {/*  navigation={true}*/}
          {/*  onSwiper={(swiper) => setSwiper(swiper)}*/}
          {/*  onSlideChange={(swiper) => setActivePack(swiper.activeIndex)}*/}
          {/*  pagination={{*/}
          {/*    clickable: true,*/}
          {/*  }}*/}
          {/*  modules={[Navigation, Pagination]}*/}
          {/*  className={styles.slider}*/}
          {/*>*/}
          {/*  {pacs.map((item) => (*/}
          {/*    <SwiperSlide key={item.id}>*/}
          {/*      <div className={styles.slideInner}>*/}
          {/*        <img className={styles.sliderImg} src={item.img} alt={item.alt} />*/}
          {/*      </div>*/}
          {/*    </SwiperSlide>*/}
          {/*  ))}*/}
          {/*</Swiper>*/}
        </div>
      </section>
    </Container>
  );
}

export default StarterPack;
