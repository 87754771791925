import { v4 as uuidv4 } from 'uuid';

const faqCategories = [
  {
    id: uuidv4(),
    label: {
      ru: 'О мероприятии',
      en: 'ABOUT THE EVENT',
      zh: 'ABOUT THE EVENT',
    },
    category: 'about',
  },
  {
    id: uuidv4(),
    label: {
      ru: 'До мероприятия',
      en: 'BEFORE THE EVENT',
      zh: 'BEFORE THE EVENT',
    },
    category: 'before',
  },
  {
    id: uuidv4(),
    label: {
      ru: 'Во время мероприятия',
      en: 'DURING THE EVENT',
      zh: 'DURING THE EVENT',
    },
    category: 'during',
  },
];

const faqArr = [
  {
    id: uuidv4(),
    section: 'about',
    locales: {
      ru: {
        question: 'Что такое Международный полумарафон «One Run»?',
        answer: (
          <>
            <p>
              Забег.РФ – ежегодное масштабное легкоатлетическое мероприятие, которое впервые состоялось 21 мая 2017 года. С тех пор география расширялась, и количество участников проекта постоянно растет. Полумарафон дважды вошел в Книгу рекордов Гиннесса, как крупнейший полумарафон с синхронным стартом в мире в 2020 и 2021 годах.
            </p>
            <p>
              В 2023 году полумарафон стал международным и под брендом «One Run» прошел в Китайской Народной Республике, Республике Беларусь, Республике Таджикистан и Республике Кыргызстан. Благодаря этому полумарафон «One Run» стал ведущим беговым событием в мире.
            </p>
          </>
        ),
      },
      en: {
        question: 'What is the «One Run» International Half Marathon?',
        answer: (
          <>
            <p>
              «One Run» is an annual large-scale track and field event, which first took place on May 21, 2017. Since then, we have expanded our geographical reach and rapidly increased our participant numbers. The half marathon has been included twice in the Guinness Book of Records as the largest half marathon with a synchronized start in the world in
              2020 and 2021.
            </p>
            <p>
              In 2023, the Half Marathon became an international event and was held in the People's Republic of China, the Republic of Belarus, the Republic of Armenia, the Republic of Tajikistan and the Republic of Kyrgyzstan under the «One Run» brand. As a result, the «One Run» Half Marathon has become the leading cross-country running event in the world.
            </p>
          </>
        ),
      },
      zh: {
        question: 'What is the «One Run» International Half Marathon?',
        answer: (
          <>
            <p>
              «One Run» is an annual large-scale track and field event, which first took place on May 21, 2017. Since then, we have expanded our geographical reach and rapidly increased our participant numbers. The half marathon has been included twice in the Guinness Book of Records as the largest half marathon with a synchronized start in the world in
              2020 and 2021.
            </p>
            <p>
              In 2023, the Half Marathon became an international event and was held in the People's Republic of China, the Republic of Belarus, the Republic of Armenia, the Republic of Tajikistan and the Republic of Kyrgyzstan under the «One Run» brand. As a result, the «One Run» Half Marathon has become the leading cross-country running event in the world.
            </p>
          </>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'about',
    locales: {
      ru: {
        question: 'Какая особенность Международного полумарафона «One Run»?',
        answer: (
          <p>
            Уникальной особенностью полумарафона является старт во всех городах-участниках в единый день.
          </p>
        ),
      },
      en: {
        question: 'What is the unique feature of the «One Run» International Half Marathon?',
        answer: (
          <p>
            The unique feature of our half-marathon is the start which is held in all participating cities on the same day.
          </p>
        ),
      },
      zh: {
        question: 'What is the unique feature of the «One Run» International Half Marathon?',
        answer: (
          <p>
            The unique feature of our half-marathon is the start which is held in all participating cities on the same day.
          </p>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'about',
    locales: {
      ru: {
        question: 'Какие цели Международного полумарафона «One Run»?',
        answer: (
          <p>
            Цель «One Run» - популяризация спорта среди населения, укрепление партнерских и дружеских взаимоотношений, а также культурный обмен между странами. Мы собираем на наших мероприятиях единомышленников, которые любят спорт и активный досуг, и стремимся к тому, чтобы массовый любительский спорт был доступен в каждой стране.
          </p>
        ),
      },
      en: {
        question: 'What are the goals of the «One Run» International Half Marathon?',
        answer: (
          <p>
            The goal of «One Run» is to popularize sports, strengthen partnerships and amicable relations, as well as encourage the exchange of cultures between our participating countries. At our events we gather like-minded people who are passionate about sports and active leisure, and strive to make mass amateur sports available in every country.
          </p>
        ),
      },
      zh: {
        question: 'What are the goals of the «One Run» International Half Marathon?',
        answer: (
          <p>
            The goal of «One Run» is to popularize sports, strengthen partnerships and amicable relations, as well as encourage the exchange of cultures between our participating countries. At our events we gather like-minded people who are passionate about sports and active leisure, and strive to make mass amateur sports available in every country.
          </p>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'about',
    locales: {
      ru: {
        question: 'Какие ценности и принципы Международного полумарафона «One Run»?',
        answer: (
          <>
            <p>
              Объединение. Мы собираем на наших мероприятиях единомышленников, которые любят спорт и активный досуг.
            </p>
            <p>
              Доступность. Мы стремимся к тому, чтобы массовый любительский спорт был доступен для широких масс и не требовал больших финансовых затрат.
            </p>
            <p>
              Здоровье. Мы качественно улучшаем жизнь, осознанно выбирая ЗОЖ, а также позитивно влияем на общество.
            </p>
          </>
        ),
      },
      en: {
        question: 'What are the values and principles of «One Run»?',
        answer: (
          <>
            <p>
              Unification. We bring together like-minded people who are passionate about sports and outdoor activities at our events.
            </p>
            <p>
              Accessibility. We strive to make mass amateur sports accessible to the masses and not requiring large financial outlays.
            </p>
            <p>
              Health. We qualitatively improve life by consciously choosing healthy lifestyles, as well as positively influence society.
            </p>
          </>
        ),
      },
      zh: {
        question: 'What are the values and principles of «One Run»?',
        answer: (
          <>
            <p>
              Unification. We bring together like-minded people who are passionate about sports and outdoor activities at our events.
            </p>
            <p>
              Accessibility. We strive to make mass amateur sports accessible to the masses and not requiring large financial outlays.
            </p>
            <p>
              Health. We qualitatively improve life by consciously choosing healthy lifestyles, as well as positively influence society.
            </p>
          </>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'about',
    locales: {
      ru: {
        question: 'Какие дистанции доступны на Международном полумарафоне «One Run»?',
        answer: (
          <p>
            Доступны для прохождения четыре дистанции – 1 км, 5 км, 10 км и 21,1 км.
          </p>
        ),
      },
      en: {
        question: 'What distances are available at the «One Run» International Half Marathon?',
        answer: (
          <p>
            Participants of the event have a choice of four distances to run - 1km, 5km, 10km and 21.1km.
          </p>
        ),
      },
      zh: {
        question: 'What distances are available at the «One Run» International Half Marathon?',
        answer: (
          <p>
            Participants of the event have a choice of four distances to run - 1km, 5km, 10km and 21.1km.
          </p>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'before',
    locales: {
      ru: {
        question: 'От чего зависит стоимость участия?',
        answer: (
          <p>
            На ранних этапах регистрации Вам предоставляется скидка. И именно объем скидки уменьшается с приближением даты старта. Величина целевого взноса формируется с учетом стоимости проведения мероприятия в отдельном городе, поэтому в каждой стране разные цены.
          </p>
        ),
      },
      en: {
        question: 'What does the cost of participation depend on?',
        answer: (
          <p>
            You get a discount in the early stages of registration. And it is the amount of the discount that decreases as the start date approaches. The amount of the target fee is formed taking into account the cost of the event in the individual city, so each country has different prices.
          </p>
        ),
      },
      zh: {
        question: 'What does the cost of participation depend on?',
        answer: (
          <p>
            You get a discount in the early stages of registration. And it is the amount of the discount that decreases as the start date approaches. The amount of the target fee is formed taking into account the cost of the event in the individual city, so each country has different prices.
          </p>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'before',
    locales: {
      ru: {
        question: 'Какие возрастные ограничения предусмотрены?',
        answer: (
          <>
            <p>
              К участию в «One Run» на дистанцию 1 км допускаются все желающие в возрасте от 6 лет и старше. Дети 6-12 лет бегут только в сопровождении родителей.
            </p>
            <p>
              К участию в «One Run» на дистанцию 5 км допускаются все желающие от 16 лет и старше.
            </p>
            <p>
              К участию в «One Run» на дистанцию 10 и 21,1 км допускаются все желающие от 18 лет и старше.
            </p>
            <p>
              Возрастные ограничения строго соблюдаются, независимо от уровня подготовки участника или наличия каких-либо дополнительных документов/разрешений. Это связано с тем, что мероприятие является любительским и массовым и в первую очередь направлено на получение удовольствия от процесса, а не на проверку себя и своей выносливости.
            </p>
          </>
        ),
      },
      en: {
        question: 'What are the age limitations?',
        answer: (
          <>
            <p>
              Everyone aged 6 years and older is allowed to participate in the 1 km «One Run». Children 6-12 years old run only if accompanied by their parents.
            </p>
            <p>
              To participate in the «One Run» at a distance of 5 km are allowed all comers from 16 years and older.
            </p>
            <p>
              Everyone from 18 years of age and older is allowed to participate in the «One Run» 10 and 21.1 km.
            </p>
            <p>
              Age restrictions are strongly enforced regardless of the participant's fitness level or any additional documents/permits. This is due to the fact that the event is an amateur and mass event and is primarily aimed at enjoying the process, rather than testing oneself and one's endurance.
            </p>
          </>
        ),
      },
      zh: {
        question: 'What are the age limitations?',
        answer: (
          <>
            <p>
              Everyone aged 6 years and older is allowed to participate in the 1 km «One Run». Children 6-12 years old run only if accompanied by their parents.
            </p>
            <p>
              To participate in the «One Run» at a distance of 5 km are allowed all comers from 16 years and older.
            </p>
            <p>
              Everyone from 18 years of age and older is allowed to participate in the «One Run» 10 and 21.1 km.
            </p>
            <p>
              Age restrictions are strongly enforced regardless of the participant's fitness level or any additional documents/permits. This is due to the fact that the event is an amateur and mass event and is primarily aimed at enjoying the process, rather than testing oneself and one's endurance.
            </p>
          </>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'before',
    locales: {
      ru: {
        question: 'Как узнать расписание стартов?',
        answer: (
          <p>
            Точное расписание мероприятия в вашем городе будет опубликовано на сайте мероприятия в каждой стране ближе к дате проведения.
          </p>
        ),
      },
      en: {
        question: 'How do I find out the schedule of starts?',
        answer: (
          <p>
            The exact schedule of the event in your city will be published on the event website in each country closer to the date of the event.
          </p>
        ),
      },
      zh: {
        question: 'How do I find out the schedule of starts?',
        answer: (
          <p>
            The exact schedule of the event in your city will be published on the event website in each country closer to the date of the event.
          </p>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'before',
    locales: {
      ru: {
        question: 'Как узнать маршрут?',
        answer: (
          <p>
            Маршрут будет опубликован на сайте мероприятия в каждой стране.
          </p>
        ),
      },
      en: {
        question: 'How do I find out the route?',
        answer: (
          <p>
            The routes will be published on each country's event website.
          </p>
        ),
      },
      zh: {
        question: 'How do I find out the route?',
        answer: (
          <p>
            The routes will be published on each country's event website.
          </p>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'during',
    locales: {
      ru: {
        question: 'Предусмотрены ли камеры хранения и раздевалки?',
        answer: (
          <p>
            В каждом городе предусмотрены раздевалки и камера хранения для участников. Мы рекомендуем приезжать заранее в спортивной форме, чтобы не тратить время на переодевание. Не сдавайте в пакете ценные или хрупкие вещи. Мы советуем взять ценные вещи с собой или оставить у тех, кто вас сопровождает.
          </p>
        ),
      },
      en: {
        question: 'Are locker rooms and changing rooms provided?',
        answer: (
          <p>
            In each city there are locker rooms and a storage room for participants. We recommend arriving in advance in your sports uniform to avoid wasting time changing clothes. Do not bring valuable or fragile items in a bag. We advise you to take valuables with you or leave them with those who accompany you.
          </p>
        ),
      },
      zh: {
        question: 'Are locker rooms and changing rooms provided?',
        answer: (
          <p>
            In each city there are locker rooms and a storage room for participants. We recommend arriving in advance in your sports uniform to avoid wasting time changing clothes. Do not bring valuable or fragile items in a bag. We advise you to take valuables with you or leave them with those who accompany you.
          </p>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'during',
    locales: {
      ru: {
        question: 'Существуют ли пит-стопы на трассе?',
        answer: (
          <p>
            На протяжении всей трассы будут расположены пит-стопы с водой и фруктами, чтобы освежиться и подзарядиться.
          </p>
        ),
      },
      en: {
        question: 'Are there pit stops on the route?',
        answer: (
          <p>
            There will be pit stops throughout the route with water and fruit to refresh and recharge.
          </p>
        ),
      },
      zh: {
        question: 'Are there pit stops on the route?',
        answer: (
          <p>
            There will be pit stops throughout the route with water and fruit to refresh and recharge.
          </p>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'during',
    locales: {
      ru: {
        question: 'Что делать, если со мной что-то случится на мероприятии?',
        answer: (
          <p>
            На каждой трассе будут работать машины скорой помощи. На старте и финише будет также расположен медицинский пункт. Пожалуйста, не стесняйтесь обращаться за помощью, если почувствуете себя плохо или заметите, что стало плохо другому участнику. В экстренном случае обращайтесь к волонтёрам или судьям, привлеките внимание окружающих и вызовите медицинскую помощь.
          </p>
        ),
      },
      en: {
        question: 'What if something happens to me at the event?',
        answer: (
          <p>
            Ambulances will be available on each route. There will also be a medical station at the start and finish line. Please do not hesitate to seek help if you feel unwell or notice that another competitor has become unwell. In case of emergency, ask for volunteers or judges, attract the attention of others and call for medical help.
          </p>
        ),
      },
      zh: {
        question: 'What if something happens to me at the event?',
        answer: (
          <p>
            Ambulances will be available on each route. There will also be a medical station at the start and finish line. Please do not hesitate to seek help if you feel unwell or notice that another competitor has become unwell. In case of emergency, ask for volunteers or judges, attract the attention of others and call for medical help.
          </p>
        ),
      },
    },
  },
  {
    id: uuidv4(),
    section: 'during',
    locales: {
      ru: {
        question: 'Могу ли я привести зрителей на мероприятие?',
        answer: (
          <p>
            Можно приходить всей семьей и с друзьями любого возраста, чтобы поддержать спортсменов на трассе и финише. К тому же для всех гостей мероприятия будут подготовлены активности и развлечения.
          </p>
        ),
      },
      en: {
        question: 'Can I bring spectators to the event?',
        answer: (
          <p>
            You can come with your family and friends of all ages to support the athletes on the route and at the finish line. In addition, there will be activities and entertainment for all guests of the event.
          </p>
        ),
      },
      zh: {
        question: 'Can I bring spectators to the event?',
        answer: (
          <p>
            You can come with your family and friends of all ages to support the athletes on the route and at the finish line. In addition, there will be activities and entertainment for all guests of the event.
          </p>
        ),
      },
    },
  },
];

export {
  faqCategories,
  faqArr,
};
