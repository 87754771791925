import './App.css';
import i18n from './i18n';
import { useLayoutEffect } from 'react';
import { Route, Routes } from 'react-router';
import MainScreen from './pages/main/main-screen';
import CountryScreen from './pages/country-screen/country-screen';

function App() {
  useLayoutEffect(() => {
    document.documentElement.lang = i18n.language;
  }, []);

  return (
    <Routes>
      <Route path="/" element={<MainScreen />} />
      <Route path="/tajikistan" element={<CountryScreen />} />
    </Routes>
  );
}

export default App;
