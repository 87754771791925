import Modal from './Modal/Modal';
// import img from '../../../assets/images/farewell-modal.webp';
import { ReactComponent as IconLinked } from '../../..//assets/images/icons/linkedin_icon.svg';
import { ReactComponent as IconInstagram } from '../../../assets/images/icons/insta_icon.svg';
import { useTranslation } from 'react-i18next';
import styles from './FarewellModal.module.scss';

function FarewellModal({ isShow, onClose }) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isShow} onClose={onClose}>
      <div className={styles.modalInner}>
        <div className={styles.content}>
          <p className={styles.title}>{t('pageTop.FarewellModal.title')}</p>
          <p>
            {t('pageTop.FarewellModal.text')}
          </p>
          <p className={styles.text}>
            {t('pageTop.FarewellModal.foot')}
          </p>
          <ul className={styles.socials}>
            <li className={styles.socialsItem}>
              <a href="https://www.instagram.com/onerunglobal">
                <IconInstagram className={styles.socialsIcon} width={42} height={42}/>
                <span className="visually-hidden">Instagram</span>
              </a>
            </li>
            <li className={styles.socialsItem}>
              <a href="https://www.linkedin.com/company/heroleague/">
                <IconLinked className={styles.socialsIcon} width={42} height={42}/>
                <span className="visually-hidden">LinkedIn</span>
              </a>
            </li>
          </ul>
        </div>
        {/* <img className={styles.img} src={img} width={644} height={280} alt="" /> */}
      </div>
    </Modal>
  );
}

export default FarewellModal;
