import styles from './gallery.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import photo1 from 'assets/images/gallery/gallery1@2x.webp';
import photo2 from 'assets/images/gallery/gallery2@2x.webp';
import photo3 from 'assets/images/gallery/gallery3@2x.webp';
import photo4 from 'assets/images/gallery/gallery4@2x.webp';
import photo5 from 'assets/images/gallery/gallery5@2x.webp';
import photo6 from 'assets/images/gallery/gallery6@2x.webp';


const photos = [
  {
    id: 1,
    img: photo1,
  },
  {
    id: 2,
    img: photo2,
  },
  {
    id: 3,
    img: photo3,
  },
  {
    id: 4,
    img: photo4,
  },
  {
    id: 5,
    img: photo5,
  },
  {
    id: 6,
    img: photo6,
  },
];

function Gallery() {
  return (
    <section className={styles.gallery}>

      <Swiper
        spaceBetween={8}
        slidesPerView={'auto'}
        className={styles.slider}
      >
        {photos.map((item) => (
          <SwiperSlide className={styles.slide} key={item.id}>
            <div className={styles.imgWrap}>
              <img className={styles.img} src={item.img} alt="Фото с мероприятия"/>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
}

export default Gallery;
