import styles from './routes-event.module.scss';
import Container from '../app/container/container';
import { ReactComponent as IconCityChange } from '../../assets/images/icons/icon-change-circle-outline-rounded.svg';
import imgMedical from '../../assets/images/medical-certificate@1x.png';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Socials from '../socials/socials';


function PlaceholderRoute() {
  return (
    <div className={styles.placeholderRoute}>
      <p className={styles.placeholderRouteTitle}>Маршрут в разработке</p>
      <p className={styles.followSocials}>Следите за анонсом в наших соц. сетях</p>
      <div className={styles.socialsWrapper}>
        <Socials horizontal />
      </div>
    </div>
  );
};

function RoutesEvent({selectedCity, onChangeStateChoiceCity}) {
  return (
    <Container>
      <section className={styles.routeEvent} id="start">
        <h2 className={styles.title}>Информация о старте</h2>

        <ul className={styles.top}>
          <li className={styles.topItem}>
            <span className={styles.label}>Ваш город:</span>
            <button
              className={styles.cityBtn}
              // onClick={() => onChangeStateChoiceCity(true)}
              type="button"
            >
              {/*<IconCityChange />*/}
              {/*Душанбе*/}
              {selectedCity?.city?.name_ru}
            </button>
          </li>
         <li className={styles.topItem}>
            <span className={styles.label}>Дата и время:</span>
            <span>4 июня</span>
          </li>
          <li className={styles.topItem}>
            <span className={styles.label}>Адрес выдачи стартовых пакетов:</span>
            <span>г. Душанбе, Центральный стадион в школе олимпийского резерва №3, 2-3 июня с 11:00 до 17:00</span>
          </li>
        </ul>

        <div className={styles.bottom}>
          <Tabs className={styles.tabs}>
            <TabPanel>
              <div className={styles.tabPanelInner}>
                <PlaceholderRoute />
              </div>
            </TabPanel>
            <TabPanel>
              <div className={styles.tabPanelInner}>
                <PlaceholderRoute />
              </div>
            </TabPanel>
            <TabPanel>
              <div className={styles.tabPanelInner}>
                <PlaceholderRoute />
              </div>
            </TabPanel>
            <TabPanel>
              <div className={styles.tabPanelInner}>
                <PlaceholderRoute />
              </div>
            </TabPanel>

            <TabList>
              <span className={styles.tabListLabel}>Форматы: </span>
              <Tab className={styles.tab}>1 Км</Tab>
              <Tab className={styles.tab}>5 Км</Tab>
              <Tab className={styles.tab}>10 Км</Tab>
              <Tab className={styles.tab}>21,1 Км</Tab>
            </TabList>
          </Tabs>

          <div className={styles.medicalWrapper}>
            <div className={styles.medical}>
              <p className={styles.medicalTitle}>Медицинская справка</p>
              <div className={styles.imgDesc}>
                <img className={styles.medicalImg} src={imgMedical} alt="Медицинская справка"/>
                <p className={styles.descriptionMedical}>
                  Чтобы получить стартовый пакет участника, тебе нужно предоставить документ, удостоверяющий личность,
                  и медицинскую справку.
                </p>
              </div>
              <a
                className={styles.medicalBtn}
                href="https://heroleague.ru/documents/zabeg/spravka.pdf"
                target="_blank"
                rel="noreferrer noopener"
              >
                Подробнее
              </a>
            </div>
            <div className={styles.medicalSocials}>
              <p>Поделиться маршрутом: </p>
              <Socials horizontal />
            </div>
          </div>
        </div>
      </section>
    </Container>
  );
}

export default RoutesEvent;
