import styles from './how-was-it.module.scss';
import Container from '../app/container/container';
import { useRef } from 'react';


function HowWasIt() {
  const videoRef = useRef(null);
  const videoBtnRef = useRef(null);

  function stateVideo() {
    videoRef.current.play();
    videoBtnRef.current.style.display = 'none';
    videoRef.current.addEventListener('play', changeStateVideo);
    videoRef.current.addEventListener('pause', pauseVideo);
  }

  function changeStateVideo () {
    this.controls = 'controls';
  }

  function pauseVideo() {
    this.controls = '';
    videoBtnRef.current.style.display = '';
  }

  return (
    <Container>
      <section className={styles.howWaIt}>
        <h2 className={styles.title}>Как это было на забеге в России?</h2>
        <p className={styles.desc}>85 городов и более 150 тысячи любителей вышли на старт.</p>
        <div className={styles.videoPlayer}>
          <video
            className={styles.video}
            onClick={stateVideo}
            ref={videoRef}
            src="https://heroleague.ru/video/zabeg2021.mp4#t=3"
          />
          <button
            className={styles.btnPlay}
            onClick={stateVideo}
            ref={videoBtnRef}
          >
            <span className="visually-hidden">Воспроизвести видео</span>
          </button>
          {/*<button className={styles.btnPlay}><span className="visually-hidden">Воспроизвести видео</span></button>*/}
          {/*<p>Длительность: 6 минут</p>*/}
          {/*<p><a className={styles.link} href="#">Ссылка: youtube.com</a></p>*/}
        </div>
      </section>
    </Container>
  );
}

export default HowWasIt;
