import styles from './faq-anchor.module.scss';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';


function FaqAnchor() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [isOpen, setIsOpen] = useState(!isMobile);

  useEffect(() => {
    if (!isMobile) {
      setTimeout(() => {
        setIsOpen(false);
      }, 10000);
    }
  }, []);

  const handleClickAnchor = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 1500);
  };

  return (
    <section className={`${styles.container} ${!isOpen ? styles.closed : ``}`}>
      <button className={styles.toggle} onClick={() => setIsOpen(!isOpen)}><span className="visually-hidden">Закрыть</span></button>
      <div className={styles.content}>
        <div className={styles.inner}>
          <h2 className={styles.title}>Частые вопросы</h2>
          <p className={styles.desc}>Специально для вас, мы собрали ответы на самые частые ваши вопросы</p>
          <a className={styles.anchor} onClick={handleClickAnchor} href="#faq">К ответам >></a>
        </div>
      </div>
    </section>
  );
}

export default FaqAnchor;
