import Popup from '../modal/modal';
import styles from './format-buy-modal.module.scss';
import { useState } from 'react';
import { HERO_API } from 'api';

const getTotalCost = (price, count = 1, insurance = 0) => {
  return (+((price * count) + (insurance * count)).toFixed(2));
};

const FormatBuyModal = ({isShowModal, onCloseModal, format}) => {
  const [insurance, setInsurance] = useState(false); // on/off insurance
  const [checkTown, setCheckTown] = useState(false);

  const handleChangeInput = () => setInsurance(!insurance);

  const handleRedirectChange = (evt) => {
    evt.preventDefault();

    if (checkTown && format.city.id !== 1111111) {
      // redirect
      window.location.href = `${HERO_API}/basket-fill-outside/event?public_id=${format.event_public_id}&city_id=${format.city.id}&format_id=${format.public_id}&insurance=${insurance}`;
    }
  }

  if (!isShowModal) return null;

  return (
    <Popup isShowModal={isShowModal} onCloseModal={onCloseModal} outsideStyles={styles}>

      <div className={styles.descBlock}>
        <h2 className={styles.title}>Подтверждение об ознакомлении</h2>
        <div className={styles.desc}>
          <ul>
            {format.requirements.map(el => <li className={styles.requirement} key={el}>{el}</li>)}
          </ul>
        </div>
        <div className={styles.formWrapper}>
          <div className={styles.cityWrap}>
            <p className={styles.cityText}>Вы выбрали город:&nbsp;&nbsp;<span className={styles.city}>{format.address}</span></p>
            <div className={styles.inputWrap}>
              <input
                defaultValue={checkTown}
                onChange={() => setCheckTown(!checkTown)}
                className={`${styles.checkbox} visually-hidden`}
                type="checkbox" id="check-town-ref"
              />
              <label className={styles.label} htmlFor="check-town-ref">С медицинской справкой ознакомлен и город подтверждаю</label>
              {
                format.insurance_available ?
                  <>
                    <input
                      value={insurance}
                      onChange={handleChangeInput}
                      className={`${styles.checkbox} visually-hidden`}
                      type="checkbox" id="check-insurance"
                    />
                    <label className={styles.label} htmlFor="check-insurance">Страховка строго 18+</label>
                  </>
                  : null
              }
            </div>
          </div>
          <div className={styles.btnWrap}>
            <button
              className={styles.btn}
              type="button" onClick={handleRedirectChange}
              disabled={!checkTown}
            >Перейти к покупке</button>
          </div>
        </div>
      </div>

      <div className={styles.costBlock}>
        <b className={styles.worth}>Стоимость:</b>
        {format.insurance_available ? <p className={styles.counts}>
          <span>Страховка</span>
          <span className={styles.cost}>{insurance ? (format.insurance_cost * 1) : 0} &#8381;</span>
        </p> : null}
        <p className={styles.counts}>
          <span>Билет</span>
          <span className={styles.cost}>{getTotalCost(format.price, 1, insurance && format.insurance_cost)} &#8381;</span>
        </p>
      </div>

    </Popup>
  )
}

export default FormatBuyModal;
