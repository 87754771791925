import styles from './entertainment.module.scss';
import { ReactComponent as IconCity } from '../../assets/images/icons/icon-city.svg';
import { ReactComponent as IconBoy } from '../../assets/images/icons/icon-boy.svg';
import { ReactComponent as IconStage } from '../../assets/images/icons/icon-stage.svg';
import { ReactComponent as IconStar } from '../../assets/images/icons/icon-star.svg';
import { ReactComponent as IconStart } from '../../assets/images/icons/icon-start.svg';
import Container from '../app/container/container';
import { motion } from "framer-motion";


const entertainments = [
  {
    id: 1,
    icon: IconCity,
    label: `Лучшие маршруты в сердце твоего города`,
  },
  {
    id: 2,
    icon: IconBoy,
    label: `Участие детей и развлечения для юных атлетов`,
  },
  {
    id: 3,
    icon: IconStage,
    label: `Шоу-программа для болельщиков и участников`,
  },
  {
    id: 4,
    icon: IconStar,
    label: `Классная награда и яркие эмоции`,
  },
  {
    id: 5,
    icon: IconStart,
    label: `4 дистанции для любого уровня подготовки`,
  },
];

const cardVariants = {
  offscreen: {
    scale: 0
  },
  onscreen: {
    scale: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8
    }
  }
};

function Entertainment() {
  return (
    <Container>
      <section className={styles.entertainment}>
        <h2 className={styles.title}>Что тебя ждет на забеге One Run?</h2>

        <ul className={styles.list}>
          {entertainments.map((item) => (
            <li className={styles.item} key={item.id}>
              <motion.div
                className="card-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
              >
                <div className="splash" />
                <motion.div className="card" variants={cardVariants}>
                  <div className={styles.iconWrap}>
                    <item.icon className={styles.icon} />
                  </div>

                </motion.div>
              </motion.div>
              <h3 className={styles.itemTitle}>{item.label}</h3>
            </li>
          ))}
        </ul>
      </section>
    </Container>
  );
}

export default Entertainment;
